import { Button, DatePicker, Divider, Dropdown, Form, Input, Modal, Select, Tag, notification } from 'antd';
import { Card, Text } from 'components';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import React, { useCallback, useContext, useState } from 'react';
import { getVehicles, updateBiker } from 'services/message.service';
import Field from 'components/basic/Field/Field';
import moment from 'moment';
import { motorcycleBrands, motorcycleColors, motorcycleModels, motorcycleYears, pistonDisplacements, transmissionTypes } from './options';
import useModal from 'hooks/useModal';
import LottiePlayer from 'lottie-react';
import processing from 'components/basic/Table/btn-processing-blue-mc.json';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const DriverVehicle = ({
    id,
    driversLicense,
    expiryDate,
    fetchDriver,
}: {
    id: string | undefined;
    driversLicense: string | undefined;
    expiryDate: string | undefined;
    fetchDriver: any
}) => {
    const [data, setData] = useState([]);
    const [requestBody, setRequestBody] = useState<any>();
    const [onEdit, setOnEdit] = useState(false);
    const confirmationModal = useModal();
    const unsaveChangesModal = useModal();
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) || 
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
    }

    const { requestState } = useFilter({
        page_size: 10,
        user_id: id,
        limit: 10,
    });

    const { request, result, loading } = useApi({
        api: getVehicles,
    });

    useMount(() => {
        fetchVehicles(requestState);
    });

    const { request: updateMcBiker, loading: updatingDriver } = useApi({
        api: updateBiker,
    });

    const fetchVehicles = useCallback(
        async (requestState: {}) => {
            try {
                const result = await request(requestState);
                if (result.status == 'success') {
                    let vehicles = result.data.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
                    setData(vehicles);
                } else {
                    // TODO: error screen
                }
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const [form] = Form.useForm();
  
    const onFinish = async (values: any) => {
        setRequestBody(values);
        confirmationModal.show();
    };

    const onSave = async () => {
        console.log(JSON.stringify(requestBody),'requestBody')
        const expiryDate = requestBody?.expiry_date;
        const paExpiryDate = requestBody?.pa_expiry_date;
        const paStartDate = requestBody?.pa_start_date;
        delete requestBody?.expiry_date;
        delete requestBody?.pa_expiry_date;
        delete requestBody?.pa_start_date;
        const values = requestBody;
        console.log('values', JSON.stringify(values));
        
        const response = await updateMcBiker({ id, body: {...values, 
          drivers_license_expiry_date: moment(expiryDate).format('YYYY-MM-DD'),
          pa_expiry_date: moment(paExpiryDate).format('YYYY-MM-DD'),
          pa_start_date: moment(paStartDate).format('YYYY-MM-DD')
        }})
        if (!response.error) {
          notification.success({
              key: 'success',
              message: 'Success',
              description: "The motorcycle details have been updated successfully for this biker.",
          });
            fetchVehicles(requestState);
            fetchDriver(id);
        } else {
          notification.error({
              key: 'error',
              message: 'Error',
              description: response.error.message,
          });
        }
        setOnEdit(false);
        confirmationModal.close();
        unsaveChangesModal.close();
        form.resetFields();
    };

    const {
        plate_number,
        chassis_number,
        color,
        vehicle_make,
        vehicle_model,
        engine_number,
        year,
        piston_displacement,
        transmission,
        pa_expiry_date,
        pa_start_date,
        case_number
    } = data[0] || {};
    if (loading) {
        return <div className="w-full h-56 items-center justify-center flex rounded">
            <LottiePlayer
                animationData={processing}
                loop
                autoplay
                style={{ width: '250px', height: '50px' }}
            />
        </div>
    }

    return (
        <div className="flex flex-col gap-4 rounded">
            <div key={plate_number}>
                <Card style="dashed">
                    {!onEdit ? (
                        <div className="w-full p-md">
                            <div className="flex justify-between w-full">
                                <Text className="uppercase" type="label">
                                    MOTORCYCLE / CAR INFORMATION 
                                </Text>
                                {hasActionAccess(PagesEnum.DRIVERS) && <Button
                                    size="small"
                                    disabled={updatingDriver}
                                    onClick={() => setOnEdit(true)}
                                >
                                    Edit
                                </Button>}
                            </div>
                            <div className="w-full mt-md">
                                <div>
                                    <Text className="text-sm font-semibold">Motorcycle / Car Details</Text>
                                    <Divider style={{ margin: '0.5rem 0' }} />
                                </div>
                                <div className="grid grid-cols-8 gap-2">
                                    <Field label="Motorcycle / Car Brand" value={vehicle_make || 'N/A'} />
                                    <Field label="Color" value={color || 'N/A'} />
                                    <Field label="Model" value={vehicle_model || 'N/A'} valueClassName='break-all'/>
                                    <Field label="Year" value={year || 'N/A'} />
                                    <Field label="Piston Displacement" value={piston_displacement || 'N/A'} />
                                    <Field label="Transmission Type" value={transmission || 'N/A'} />
                                </div>
                            </div>
                            <div className="w-full mt-lg">
                                <div>
                                    <Text className="text-sm font-semibold">
                                        Identification Numbers
                                    </Text>
                                    <Divider style={{ margin: '0.5rem 0' }} />
                                </div>
                                <div className="grid grid-cols-8 gap-2">
                                    <Field label="Engine Number" value={engine_number || 'N/A'} valueClassName='break-all' />
                                    <Field label="Chassis Number" value={chassis_number || 'N/A'} valueClassName='break-all'/>
                                    <Field label="MV File or Plate Number" value={plate_number || 'N/A'} valueClassName='break-all'/>
                                    <Field
                                        label="Driver's License Number"
                                        valueClassName='text-ellipsis break-all'
                                        value={driversLicense || 'N/A'}
                                    />
                                </div>
                            </div>
                            <div className="w-full mt-lg">
                                <div>
                                    <Text className="text-sm font-semibold">Expiration Dates</Text>
                                    <Divider style={{ margin: '0.5rem 0' }} />
                                </div>
                                <div>
                                    <Field
                                        label="Driver's License Expiry Date"
                                        valueClassName='text-ellipsis'
                                        value={expiryDate !== '' || expiryDate ? moment(expiryDate).format('YYYY-DD-MM') : 'N/A'}
                                    />
                                </div>
                            </div>
                            <div className="w-full mt-lg">
                                <div>
                                    <Text className="text-sm font-semibold">Provisional Authority (for Cars only)</Text>
                                    <Divider style={{ margin: '0.5rem 0' }} />
                                </div>
                                <div className="grid grid-cols-8 gap-2">
                                    <Field label="Case Number" value={case_number || 'N/A'} valueClassName='break-all' />
                                    <Field
                                        label="PA Start Date Issued"
                                        valueClassName='text-ellipsis'
                                        value={pa_start_date ? moment(pa_start_date).format('YYYY-DD-MM') : 'N/A'}
                                    />
                                    <Field
                                        label="PA Expiry Date"
                                        valueClassName='text-ellipsis'
                                        value={pa_expiry_date ? moment(pa_expiry_date).format('YYYY-DD-MM') : 'N/A'}
                                    />
                                </div>
                                
                            </div>
                        </div>
                    ) : (
                        <div className="w-full p-md">
                            <Form
                                form={form}
                                name="motorcycle_form"
                                layout='vertical'
                                initialValues={{
                                    plate_number,
                                    chassis_number,
                                    color,
                                    motorcycle_make: vehicle_make,
                                    motorcycle_model: vehicle_model,
                                    engine_number,
                                    motorcycle_year: year,
                                    piston_displacement,
                                    transmission_type: transmission,
                                    drivers_license: driversLicense,
                                    pa_expiry_date: pa_expiry_date !== '' && pa_expiry_date ? moment(pa_expiry_date) : null,
                                    pa_start_date: pa_start_date !== '' && pa_start_date ? moment(pa_start_date) : null,
                                    expiry_date: expiryDate !== '' || expiryDate ? moment(expiryDate) : null,
                                    case_number
                                }}
                                onFinish={onFinish}
                            >
                            <div className="flex justify-between w-full">
                                <Text className="uppercase" type="label">
                                MOTORCYCLE / CAR INFORMATION 
                                </Text>
                                <div className='flex gap-2'>
                                    <Button size="small" onClick={() => {
                                        if (form.isFieldsTouched()) {
                                            setRequestBody(form.getFieldsValue());
                                            unsaveChangesModal.show();
                                        } else {
                                            setOnEdit(!onEdit)
                                            form.resetFields();
                                        }
                                    }}>
                                        Cancel
                                    </Button>
                                    <Button size="small" htmlType="submit" type='primary'>
                                        Save
                                    </Button>
                                </div>
                                
                            </div>
                                <div className="w-full mt-md">
                                    <div>
                                        <Text className="text-sm font-semibold">
                                        Motorcycle / Car Details 
                                        </Text>
                                        <Divider style={{ margin: '0.5rem 0' }} />
                                    </div>
                                    <div className="grid grid-cols-8 gap-2">
                                        <Form.Item 
                                            name="motorcycle_make" 
                                            label="Motorcycle / Car Brand"
                                            rules={[{ required: true,  message: 'Motorcycle / Car Brand is required' }]}
                                        >
                                            <Select options={motorcycleBrands}/>
                                        </Form.Item>
                                        <Form.Item name="color" label="Color" rules={[{ required: true,  message: 'Color is required' }]}>
                                            <Select options={motorcycleColors}/>
                                        </Form.Item>
                                        <Form.Item name="motorcycle_model" label="Model" 
                                            rules={[{ required: true, message: 'Model is required' },
                                                { max: 30, message: "Model cannot be longer than 30 characters"}]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="motorcycle_year" label="Year" rules={[{ required: true, message: 'Year is required' }]}>
                                            <Select options={motorcycleYears}/>
                                        </Form.Item>
                                        <Form.Item name="piston_displacement" label="Piston Displacement" rules={[{ required: true,  message: 'Piston Displacement is required' }]}>
                                            <Select options={pistonDisplacements}/>
                                        </Form.Item>
                                        <Form.Item name="transmission_type" label="Transmission Type" rules={[{ required: true, message: 'Transmission Type is required' }]}>
                                            <Select options={transmissionTypes}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="w-full mt-lg">
                                    <div>
                                        <Text className="text-sm font-semibold">
                                            Identification Numbers
                                        </Text>
                                        <Divider style={{ margin: '0.5rem 0' }} />
                                    </div>
                                    <div className="grid grid-cols-8 gap-2">
                                        <Form.Item name="engine_number" label="Engine Number" rules={[{ required: true, message: 'Engine Number is required' },
                                            { max: 17, message: "Engine Number cannot be longer than 17 characters"}]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="chassis_number" label="Chassis Number" rules={[{ required: true, message: 'Chassis Number is required' }, 
                                            { max: 17, message: "Chassis Number cannot be longer than 17 characters"}]}>
                                            <Input  />
                                        </Form.Item>
                                        <Form.Item name="plate_number" label="MV File or Plate Number" 
                                            rules={[{ required: true,  message: 'Plate Number is required' }, 
                                            { max: 20, message: "Plate Number cannot be longer than 20 characters"}]}>
                                            <Input  />
                                        </Form.Item>
                                        <Form.Item
                                            name="drivers_license"
                                            label="Driver's License Number"
                                            rules={[{ required: true, message: 'License Number is required'},
                                                    { max: 13, message: "License Number cannot be longer than 13 characters"}]}
                                        >
                                            <Input  />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="w-full mt-lg">
                                    <div>
                                        <Text className="text-sm font-semibold">Expiration Dates</Text>
                                        <Divider style={{ margin: '0.5rem 0' }} />
                                    </div>
                                    <div>
                                        <Form.Item
                                            name="expiry_date"
                                            label="Driver's License Expiry Date"
                                            rules={[{ required: true, message: 'License Expiry Date is required'}]}
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="w-full mt-lg">
                                    <div>
                                        <Text className="text-sm font-semibold">Provisional Authority (for Cars only)</Text>
                                        <Divider style={{ margin: '0.5rem 0' }} />
                                    </div>
                                    <div className="grid grid-cols-8 gap-2">
                                        <Form.Item name="case_number" label="Case Number" rules={[{ required: true, message: 'Case Number is required' },
                                            { max: 17, message: "Case Number cannot be longer than 17 characters"}]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="pa_start_date"
                                            label="PA Start Date Issued"
                                            rules={[{ required: true, message: 'PA Start Date is required'}]}
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                        <Form.Item
                                            name="pa_expiry_date"
                                            label="PA Expiry Date"
                                            rules={[{ required: true, message: 'PA Expiry Date is required'}]}
                                        >
                                            <DatePicker disabledDate={(current) => current && current.isSameOrBefore(Date.now(), 'day')}/>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </Card>
            </div>
            <Modal {...confirmationModal} title='Update Motorcycle Details' onOk={onSave} okText='Save'
               >
                Are you sure you want to update the motorcycle details for this biker?
            </Modal>
            <Modal {...unsaveChangesModal} title='You have unsaved changes' onOk={onSave} 
                    cancelText='Discard Changes & Leave' 
                    onCancel={() => {
                        unsaveChangesModal.close()
                        setOnEdit(!onEdit)
                        form.resetFields();
                    }}
                    okText='Save'
                    >
                Any unsaved changes will be lost when you leave this page. Are you sure you want to leave this page?
            </Modal>
        </div>
    );
};

export default DriverVehicle;
